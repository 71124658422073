import { BaseEventHandler, ServicelogV2BM } from '@bm-js/h2o-shared';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { parseDate } from '../utils/parseDate';
import { parseSEK } from '../utils/parseSEK';
import DotsDropdown from './DotsDropdown';
import Button from './Button';
import { colors } from '../utils/siteVars';
import { Link } from 'gatsby';
import { parseCustomerName } from '../utils/parseCustomerName';

const StyledServicelogInvoicingCard = styled.div`
  select {
    border: 1px solid ${colors.mediumGrey};
    margin: 0;
  }
`;

type Props = {
  servicelog: ServicelogV2BM;
  updateStatus: (log: ServicelogV2BM, status: string) => Promise<void>;
  goToEdit: (log: ServicelogV2BM) => void;
  getPdf: (log: ServicelogV2BM, download?: boolean) => void;
};

const ServicelogInvoicingCard = ({
  servicelog,
  updateStatus,
  goToEdit,
  getPdf,
}: Props) => {
  const handleStatusChange: BaseEventHandler = (e) => {
    updateStatus(servicelog, e.target.value);
  };

  const handleClick = (download?: boolean) => {
    getPdf(servicelog, download);
  };

  const totalSum = useMemo(() => {
    const rowSums = servicelog.table.map((row) => {
      return row.priceExVat * row.quantity;
    });
    const totalSum = rowSums.reduce((acc, curr) => acc + curr, 0);
    return totalSum;
  }, [servicelog]);

  return (
    <StyledServicelogInvoicingCard className="service-invoicing-grid">
      <Link
        to={`/servicelogs/?id=${servicelog.customer._id}&name=${parseCustomerName(servicelog.customer)}`}
        className="ellipsis"
      >
        {servicelog.customer.customerInformation.name || '-'}
      </Link>
      <p className="center">{servicelog.offerNumber}</p>
      <p className="center">{parseDate(servicelog.offerDate) || '-'}</p>
      <p className="center">{parseDate(servicelog.workEndDate) || '-'}</p>
      <p className="center">{parseSEK(totalSum)}</p>
      <select
        className="center"
        value={servicelog.status}
        onChange={handleStatusChange}
      >
        <option>Öppen</option>
        <option>Klar för granskning</option>
        <option>Klar för fakturering</option>
        <option>Fakturerad</option>
      </select>
      <DotsDropdown
        className="end"
        content={
          <>
            <Button small onClick={() => handleClick()}>
              Förhandsvisa
            </Button>
            <Button small onClick={() => goToEdit(servicelog)}>
              Redigera
            </Button>
            <Button small onClick={() => handleClick(true)}>
              Ladda ned
            </Button>
          </>
        }
      />
    </StyledServicelogInvoicingCard>
  );
};

export default ServicelogInvoicingCard;
