import { OfferBM, ServicelogV2BM } from '@bm-js/h2o-shared';
import React from 'react';
import Card from './Card';
import ServicelogInvoicingCard from './ServicelogInvoicingCard';
import styled from 'styled-components';
import OfferInvoicingCard from './OfferInvoicingCard';

const StyledOfferInvoicingTab = styled.div`
  .service-invoicing-grid {
    display: grid;
    grid-template-columns: 3fr 2fr 2fr 2fr 2fr 60px;
    gap: 1rem;
    align-items: center;
    p,
    a {
      margin: 0;
      white-space: nowrap;
    }
    a {
      text-decoration: underline;
    }
    .center {
      text-align: center;
    }
    .end {
      text-align: end;
      justify-self: flex-end;
    }
    .ellipsis {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &__headers {
      font-weight: 700;
      padding: 0 1rem;
      margin-bottom: 0.5rem;
    }
  }

  @media (max-width: 1000px) {
    .service-invoicing-grid {
      grid-template-columns: 2fr 1fr 60px;
      p:not(.mobile) {
        display: none;
      }
    }
  }
`;

type Props = {
  offers: OfferBM[];
  updateStatus: (log: OfferBM, status: string) => Promise<void>;
  getPdf: (log: OfferBM, download?: boolean) => void;
};

const OfferInvoicingTab = ({ offers, updateStatus, getPdf }: Props) => {
  if (!offers.length) return <p>Inga offerter hittades</p>;
  return (
    <StyledOfferInvoicingTab>
      <div className="service-invoicing-grid service-invoicing-grid__headers">
        <p className="mobile">Kund</p>
        <p className="center">Offernummer</p>
        <p className="center">Offertdatum</p>
        <p className="center">Summa</p>
        <p className="mobile center">Status</p>
        <p className="mobile end">Åtgärder</p>
      </div>
      {offers.map((offer) => (
        <Card inner key={offer._id}>
          <OfferInvoicingCard
            offer={offer}
            updateStatus={updateStatus}
            getPdf={getPdf}
          />
        </Card>
      ))}
    </StyledOfferInvoicingTab>
  );
};

export default OfferInvoicingTab;
