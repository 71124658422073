import { BaseEventHandler, OfferBM } from '@bm-js/h2o-shared';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { parseDate } from '../utils/parseDate';
import { parseSEK } from '../utils/parseSEK';
import DotsDropdown from './DotsDropdown';
import Button from './Button';
import { colors } from '../utils/siteVars';
import { Link } from 'gatsby';
import { parseCustomerName } from '../utils/parseCustomerName';

const StyledOfferInvoicingCard = styled.div`
  select {
    border: 1px solid ${colors.mediumGrey};
    margin: 0;
  }
`;

type Props = {
  offer: OfferBM;
  updateStatus: (log: OfferBM, status: string) => Promise<void>;
  getPdf: (log: OfferBM, download?: boolean) => void;
};

const OfferInvoicingCard = ({ offer, updateStatus, getPdf }: Props) => {
  const handleStatusChange: BaseEventHandler = (e) => {
    updateStatus(offer, e.target.value);
  };

  const handleClick = (download?: boolean) => {
    getPdf(offer, download);
  };

  const totalSum = useMemo(() => {
    const rowSums = offer.table.map((row) => {
      return row.priceExVat * row.quantity;
    });
    const totalSum = rowSums.reduce((acc, curr) => acc + curr, 0);
    return totalSum;
  }, [offer]);

  return (
    <StyledOfferInvoicingCard className="service-invoicing-grid">
      <Link
        to={`/offers/?id=${offer.customer._id}&name=${parseCustomerName(offer.customer)}`}
        className="ellipsis"
      >
        {offer.customer.customerInformation.name || '-'}
      </Link>
      <p className="center">{offer.offerNumber}</p>
      <p className="center">{parseDate(offer.offerDate) || '-'}</p>
      <p className="center">{parseSEK(totalSum)}</p>
      <select
        className="center"
        value={offer.orderStatus}
        onChange={handleStatusChange}
      >
        <option>Öppen</option>
        <option>Avböjd</option>
        <option value="Beställd">Beställd - skapa servicelogg</option>
      </select>
      <DotsDropdown
        className="end"
        content={
          <>
            <Button small onClick={() => handleClick()}>
              Förhandsvisa
            </Button>
            <Button small onClick={() => handleClick(true)}>
              Ladda ned
            </Button>
          </>
        }
      />
    </StyledOfferInvoicingCard>
  );
};

export default OfferInvoicingCard;
